<template>
  <div class="w-100 transition">
    <GenericReport
      :title="fullReport ? 'Relatório de Inventário' : 'Relatório de Inventário de Materiais'"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      :unidades="unidades"
      tipoRelatorio="relatorioInventario"
    />
  </div>
</template>

<script>

import GenericReport from '@/components/Table/GenericReport';
import unidadeServices from '../../../services/unidade';

export default {
  name: 'relatorioInventario',
  components: {
    GenericReport,
  },
  data() {
    return {
      route: 'report/inventario',
      unidades: [],
      topFilters: {
        id_unidade: {
          type: 'select',
          firstOption: {
            value: null,
            label: 'Unidade',
          },
          options: [
            { value: -1, text: 'Rede' },
          ],
        },
        id_setor: {
          type: 'select',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Setor',
          },
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        id_tipo_material: {
          type: 'genericSelect',
          label: 'Tipo de Material',
          route: 'tipoMaterial',
        },
        id_setor_atual: {
          type: 'genericSelect',
          label: 'Setor Atual',
          route: 'setor',
        },
      },
      fields: [
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
        },
        {
          label: 'ID',
          key: 'shownId',
        },
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Cor',
          key: 'cor',
        },
        {
          label: 'Peças Cadastradas',
          key: 'quantidade_itens',
          formatter: (value) => value || '-',
        },
        {
          label: 'Pertence a',
          key: 'pertenceA',
        },
        {
          label: 'Localização atual',
          key: 'setorAtual',
        },
        {
          label: 'Ultima modificação',
          key: 'ultimaModificacao',
        },
        {
          label: 'Ultimo processo',
          key: 'ultimoProcesso',
        },
        {
          label: 'Validade',
          key: 'validade',
        },
        {
          label: 'Nº de ciclos',
          key: 'numeroCiclos',
        },
      ],
      fullReport: null,
    };
  },
  mounted() {
    this.fullReport = Boolean(process.env.VUE_APP_PROJECT === 'materdei');
  },
  async created() {
    await this.addFilterDateInTopFilters();
    this.unidades = await unidadeServices.getUnidades();
    this.topFilters.id_unidade.options = this.topFilters.id_unidade.options.concat(this.unidades.map((unid) => ({
      value: unid.id_unidade,
      text: unid.nome,
    })));
  },

  methods: {
    addFilterDateInTopFilters() {
      const filterDate = Boolean(process.env.VUE_APP_PROJECT === 'oculare');
      if (filterDate) {
        this.topFilters.date = {
          type: 'rangeDate',
          defaultInitDate: null,
          defaultEndDate: null,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
